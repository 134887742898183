import { PropsWithChildren, useState } from 'react'
import clsx from 'clsx'
import { TbAffiliate, TbCalendarMonth, TbTopologyRing2 } from 'react-icons/tb'
import { Tab } from '@headlessui/react'
import Button from '@integration-app/ui/Button'
import Radiobutton from '@integration-app/ui/Radio'
import Input from '@integration-app/ui/Input'
import Textarea from '@integration-app/ui/Textarea'
import useEventStopPropagation from '@integration-app/ui/hooks/useEventStopPropagation'
import useAuth from 'contexts/auth'
import useApi from 'hooks/useApi'
import { Popup } from '../../routes/components/Popup'
import { CalendlyPopupButton } from './CalendlyWidget'
import classes from './WaitingForApproval.module.css'

export default function WaitingForApproval() {
  return (
    <Popup className={classes.popup} open size={'md'} variant={'narrow'}>
      <Popup.Content
        className={classes.popup_content}
        data-cy='private-beta-container'
      >
        <GetDemoBlock />
        <StartTrialHeaderBlock className={'mt-8 mb-5'} />

        <WhatAreYouLooking />
      </Popup.Content>
      <Popup.Footer className={classes.popup_footer}>
        <Footer />
      </Popup.Footer>
    </Popup>
  )
}

function GetDemoBlock() {
  return (
    <div className={classes.startDemoBlock}>
      <p>
        <TbCalendarMonth /> Not ready to start a trial yet?
      </p>
      <CalendlyPopupButton
        className={classes.startDemoBlock_button}
        variant={'secondary'}
      >
        Get a live Demo
      </CalendlyPopupButton>
    </div>
  )
}

function StartTrialHeaderBlock({ className }: { className?: string }) {
  return (
    <div className={clsx(classes.startTrialHeader, className)}>
      <h1 className={clsx(classes.panel_header, 'likeH2')}>
        Start your Free Trial
      </h1>
      <p className={classes.panel_subheader}>
        ...after answering a few questions that help us understand what you need
      </p>
    </div>
  )
}

function WhatAreYouLooking({ className }: { className?: string }) {
  const [selectedIndex, setSelectedIndex] = useState<null | 'left' | 'right'>(
    null,
  )
  return (
    <div className={clsx(classes.whatAreYouLooking, className)}>
      <h3>What are you looking to do?</h3>
      <Tab.Group>
        <Tab.List className={classes.tabList}>
          <TabButton
            Icon={<TbAffiliate />}
            onClick={() => setSelectedIndex('left')}
            selected={selectedIndex === 'left'}
          >
            Add integrations to a Saas application I'm building
          </TabButton>
          <TabButton
            Icon={<TbTopologyRing2 />}
            onClick={() => setSelectedIndex('right')}
            selected={selectedIndex === 'right'}
          >
            Integrate Saas applications I'm using with each other
          </TabButton>
        </Tab.List>
        {selectedIndex === 'left' && <QuickAccessRequestForm />}
        {selectedIndex === 'right' && (
          <div className={'text-neutral05 px-4 sm:pl-6 sm:w-1/2 sm:ml-auto'}>
            <p>
              Integration.app is built for SaaS product teams who want to add
              integrations to their apps{' '}
            </p>
          </div>
        )}
      </Tab.Group>
    </div>
  )
}

function TabButton({
  children,
  className,
  Icon,
  onClick,
  selected,
}: PropsWithChildren<{
  Icon?: React.ReactNode
  className?: string
  onClick?: () => void
  selected?: boolean
}>) {
  return (
    <Tab
      className={clsx(
        classes.tab,
        selected && classes.tab__selected,
        className,
      )}
      onClick={onClick}
    >
      {Icon && <div className={classes.tab_icon}>{Icon}</div>}
      <p>{children}</p>
    </Tab>
  )
}

function QuickAccessRequestForm() {
  const [loading, setLoading] = useState(false)
  const [appUrl, setAppUrl] = useState('')
  const [useCase, setUseCase] = useState('')
  const [readyToTrial, setReadyToTrial] = useState('')
  const [source, setSource] = useState('')
  const [quickAccessFormComplete, setQuickAccessFormComplete] = useState(false)

  const { self } = useAuth()
  const { apiClient } = useApi()

  async function handleFormSubmit() {
    setLoading(true)
    const form = {
      appUrl,
      useCase,
      readyToTrial,
      source,
    }
    await apiClient.post('/request-trial', form)
    setLoading(false)
    setQuickAccessFormComplete(true)
  }

  if (quickAccessFormComplete || self?.user?.trialRequested) {
    return <QuickAccessRequestSubmitted />
  }

  return (
    <form
      className={classes.quickAccessForm}
      onSubmit={useEventStopPropagation(handleFormSubmit)}
    >
      <legend>Tell us about your app and integration needs</legend>
      <div className={classes.quickAccessForm_input}>
        <label htmlFor={'appUrl'}>Your SaaS app URL:</label>
        <Input
          className={classes.input}
          value={appUrl}
          onChange={(e) => setAppUrl(e.target.value)}
          required={true}
          type='text'
          id='appUrl'
          disabled={loading}
        />
      </div>
      <div className={classes.quickAccessForm_textarea}>
        <label htmlFor={'useCase'}>
          What other SaaS apps do you want to integrate it with?
        </label>
        <Textarea
          className={classes.textarea}
          value={useCase}
          onChange={(e) => setUseCase(e.target.value)}
          required={true}
          name='useCase'
          disabled={loading}
        />
      </div>
      <div className={classes.quickAccessForm_radio}>
        <label htmlFor={'readyToTrial'}>
          When will you be ready to run a 30-day proof-of-concept of
          integration.app?
          <small>
            We offer a free 30-day trial of our product during which we help you
            build a working prototype of your use case(s) and decide whether you
            want to subscribe. This usually requires a few hours of time from
            your side
          </small>
        </label>
        <RadioGroupItem
          name={'readyToTrial'}
          value={readyToTrial}
          labels={[
            'Right now',
            'In the next few weeks',
            'In the next year',
            "I don't have specific plans - just looking around",
          ]}
          onChange={(value) => setReadyToTrial(value)}
          required={true}
          disabled={loading}
        />
      </div>
      <div className={classes.quickAccessForm_textarea}>
        <label htmlFor={'source'}>How did you hear about us?</label>
        <Textarea
          className={classes.textarea}
          value={source}
          onChange={(e) => setSource(e.target.value)}
          name='useCase'
          disabled={loading}
        />
      </div>
      <div className={classes.quickAccessForm_submit}>
        <Button
          type='submit'
          variant={'secondary'}
          disabled={loading}
          loading={loading}
        >
          Submit
        </Button>
      </div>
    </form>
  )
}

function QuickAccessRequestSubmitted() {
  return (
    <div className={classes.quickAccessFormComplete}>
      <h2>Thank you!</h2>
      <p>We've got your information and will get in touch with you soon.</p>
    </div>
  )
}

function RadioGroupItem({
  labels,
  value,
  name,
  onChange,
  required,
  disabled,
}: {
  labels: string[]
  value: string
  name: string
  onChange?: (value: string) => void
  required?: boolean
  disabled?: boolean
}) {
  return (
    <div className={classes.radiogroup}>
      {labels.map((label) => (
        <RadioGroup
          checked={label === value}
          key={label}
          label={label}
          name={name}
          onChange={onChange}
          required={required}
          disabled={disabled}
        />
      ))}
    </div>
  )
}

function RadioGroup({
  onChange,
  label,
  name,
  checked,
  required,
  disabled,
}: {
  onChange?: (value: string) => void
  label: string
  name: string
  checked?: boolean
  required?: boolean
  disabled?: boolean
}) {
  return (
    <label className={classes.radiogroup_item}>
      <Radiobutton
        name={name}
        value={label}
        onChange={() => onChange?.(label)}
        checked={checked}
        required={required}
        disabled={disabled}
      />
      {label}
    </label>
  )
}

function Footer() {
  const { self, logout } = useAuth()
  const userName = self?.user?.name
  return (
    <>
      {userName && <span>Not {userName}?</span>}
      <Button
        variant={'secondary'}
        onClick={() => {
          void logout()
        }}
      >
        Logout
      </Button>
    </>
  )
}
