import GenericPopup, {
  GenericPopupContentProps,
  GenericPopupFooterProps,
  GenericPopupHeaderProps,
  GenericPopupNotificationProps,
  GenericPopupRootProps,
} from '@integration-app/ui/Popup'
import classes from './Popup.module.css'
import clsx from 'clsx'

export type PopupRootProps = GenericPopupRootProps & {
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | 'custom'
  fixedHeight?: string | boolean
  variant?: 'default' | 'narrow'
}

type PopupRootComponentType = (props: PopupRootProps) => JSX.Element | null
type PopupComponentType = PopupRootComponentType & {
  Content: (props: GenericPopupContentProps) => JSX.Element | null
  Notification: (props: GenericPopupNotificationProps) => JSX.Element | null
  Header: (props: GenericPopupHeaderProps) => JSX.Element | null
  Footer: (props: GenericPopupFooterProps) => JSX.Element | null
}

function PopupRoot({
  children,
  size = 'lg',
  fixedHeight,
  className,
  variant,
  style = {},
  ...props
}: PopupRootProps) {
  const popupStyles = {
    ...style,
    ...(typeof fixedHeight === 'string'
      ? {
          '--popup-fixed-height': fixedHeight,
        }
      : {}),
  }

  return (
    <GenericPopup
      className={clsx(
        classes.popup,
        variant && classes.popup__narrow,
        getClassBySize(size),
        !!fixedHeight && classes.popup__fixed_height,
        className,
      )}
      {...props}
      style={popupStyles}
    >
      {children}
    </GenericPopup>
  )
}

function PopupContent({
  children,
  className,
  ...props
}: GenericPopupContentProps) {
  return (
    <GenericPopup.Content
      className={clsx(classes.content, className)}
      {...props}
    >
      {children}
    </GenericPopup.Content>
  )
}

function PopupHeader({
  children,
  className,
  ...props
}: GenericPopupHeaderProps) {
  return (
    <GenericPopup.Header className={clsx(classes.header, className)} {...props}>
      {children}
    </GenericPopup.Header>
  )
}

function PopupFooter({
  children,
  className,
  ...props
}: GenericPopupFooterProps) {
  return (
    <GenericPopup.Footer className={clsx(classes.footer, className)} {...props}>
      {children}
    </GenericPopup.Footer>
  )
}

function PopupNotification({
  children,
  className,
  ...props
}: GenericPopupNotificationProps) {
  return (
    <GenericPopup.Notification
      className={clsx(classes.header, className)}
      {...props}
    >
      {children}
    </GenericPopup.Notification>
  )
}

export const Popup: PopupComponentType = Object.assign(PopupRoot, {
  Content: PopupContent,
  Header: PopupHeader,
  Footer: PopupFooter,
  Notification: PopupNotification,
})

function getClassBySize(size?: PopupRootProps['size']) {
  switch (size) {
    case 'custom':
      return null
    case 'sm':
      return classes.popup__sm
    case 'md':
      return classes.popup__md
    case 'lg':
      return classes.popup__lg
    case 'xl':
      return classes.popup__xl
    case '2xl':
      return classes.popup__2xl
    case '3xl':
      return classes.popup__3xl
    default:
      return classes.popup__lg
  }
}
