import { Button } from '@integration-app/ui'
import useApi from 'hooks/useApi'
import useLocalStorage from 'hooks/useLocalStorage'
import { Popup } from 'routes/components/Popup'

export function TrialExpired() {
  const { apiClient } = useApi()

  const [requestedSubscriptionDate, setRequestedSubscriptionDate] =
    useLocalStorage('requestedSubscriptionDate', '')
  const [requestedTrialExtensionDate, setRequestedTrialExtensionDate] =
    useLocalStorage('requestedTrialExtensionDate', '')

  const canRequestSubscription =
    !requestedSubscriptionDate ||
    new Date().getTime() - new Date(requestedSubscriptionDate).getTime() >
      7 * 24 * 60 * 60 * 1000

  const canRequestTrialExtension =
    !requestedTrialExtensionDate ||
    new Date().getTime() - new Date(requestedTrialExtensionDate).getTime() >
      7 * 24 * 60 * 60 * 1000

  const requestSubscription = async () => {
    setRequestedSubscriptionDate(new Date().toISOString())
    await apiClient.post('/request-subscription')
  }

  const requestTrialExtension = async () => {
    setRequestedTrialExtensionDate(new Date().toISOString())
    await apiClient.post('/request-trial-extension')
  }

  return (
    <Popup open size={'md'} variant={'narrow'}>
      <Popup.Content className='text-center'>
        <p className='mt-32'>
          <h1>Your free trial has expired</h1>
          You can continue using the product after you subscribe.
          <br /> If you need more time to evaluate integration.app, please
          request the trial extension.
        </p>
        <div className='my-8 flex flex-row gap-2 justify-center items-center'>
          {canRequestTrialExtension && (
            <Button
              onClick={requestSubscription}
              variant='primary'
              disabled={!canRequestSubscription}
            >
              {canRequestSubscription
                ? 'Request Subscription'
                : 'Subscription Requested. Someone will be in touch with you soon.'}
            </Button>
          )}
          {canRequestSubscription && canRequestTrialExtension && (
            <span>or</span>
          )}
          {canRequestSubscription && (
            <Button
              onClick={requestTrialExtension}
              variant='secondary'
              disabled={!canRequestTrialExtension}
            >
              {canRequestTrialExtension
                ? 'Request Trial Extension'
                : 'Trial Extension Requested. Someone will be in touch with you soon.'}
            </Button>
          )}
        </div>
      </Popup.Content>
    </Popup>
  )
}
