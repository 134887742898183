import { EmptyPageLoader } from 'components/Loader'
import WaitingForApproval from 'components/Workspaces/WaitingForApproval'
import useAuth from 'contexts/auth'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { LayoutBlackWithLogo } from '../routes/components/LayoutBlackWithLogo'
import { TrialExpired } from './Workspaces/TrialExpired'

export function RequiredAuthWrapper({ children, skipWhitelist = false }) {
  const router = useRouter()
  const { loading, self, login } = useAuth()

  const unauthorized = !loading && !self?.user

  const isDocsRoute = router.pathname === '/w/[workspaceId]/docs/[...docPath]'
  const hasDocPath = router.query.docPath?.length

  // redirecting to anonymous docs if user unauthorized but came from workspace url
  useEffect(() => {
    if (unauthorized && isDocsRoute && hasDocPath) {
      void router.push({
        pathname: '/docs/[...docPath]',
        query: { docPath: router.query.docPath },
      })
    }
  }, [isDocsRoute, unauthorized, hasDocPath])

  if (loading) {
    return <EmptyPageLoader />
  } else if (self?.user) {
    if (self.user.needsApproval && !skipWhitelist) {
      return (
        <LayoutBlackWithLogo>
          <WaitingForApproval />
        </LayoutBlackWithLogo>
      )
    }

    const hasOrgs = self.orgs?.length
    const hasNonTrialOrgs = self.orgs?.some(
      (org) => !org.trialEndDate || new Date(org.trialEndDate) >= new Date(),
    )
    if (hasOrgs && !hasNonTrialOrgs) {
      return (
        <LayoutBlackWithLogo>
          <TrialExpired />
        </LayoutBlackWithLogo>
      )
    }

    return <>{children}</>
  } else {
    if (isDocsRoute) {
      return <EmptyPageLoader />
    } else {
      login()
      return null
    }
  }
}
